.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cq-combobox {
  text-align: left;
  line-height: 2rem;
}

.cq-combobox .slds-combobox__form-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
}

.cq-input-textarea {
  display: none;
  overflow: hidden;
  box-shadow: none;
  outline: none;
  resize: none;
  width: 21rem !important;
}

.cq-combobox-disabled .slds-combobox__form-element {
  display: flex;
  color: #6b6865;
  background-color: #ecebea;
}

.cq-combobox-disabled > .slds-combobox__form-element > span {
  margin: 5px !important;
}

.cq-combobox-disabled .slds-combobox__form-element .slds-input__icon_right {
  visibility: hidden !important;
}

.cq-combobox-disabled .slds-combobox__input {
  padding: 0.25rem 1rem !important;
}

.cq-combobox .slds-combobox__input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.cq-table-textarea {
  overflow: hidden;
  border: 0.5px solid rgb(232,232,232);
  box-shadow: none;
  outline: none;
  resize: none;
  margin: 0.15rem;
}

.cq-column-label{
  text-wrap: wrap;
  overflow-wrap: break-word;
}
.cq-column .slds-combobox_container {
  width: 95%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cq-grid-input {
  border: none;
 /** width: 25rem;*/
  padding: 0.5rem;
  box-shadow: none;
  outline: none;
}
.cq-grid-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem !important;
}
.cq-form-table {
  align-items: flex-start;
  gap: 0.5rem;
  overflow: overlay;
  overflow-y: hidden;
}
.cq-form-table > table {
  border: 1px solid lightgrey;
}
.cq-form-table > table > th{
  cursor: col-resize;
}

.cq-grid-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem !important;
}

.cq-form-table {
  align-items: flex-start;
  gap: 0.5rem;
  overflow: overlay;
}

.cq-form-table > table {
  border: 1px solid lightgrey;
}

.cq-form-logo {
  max-width: 120px;
}

.cq-page {
  padding: 1rem ; 
  background: #E5E5E5;
  overflow:hidden;
}

fieldset .slds-form-element__legend {
  font-weight: normal !important;
}

.cq-checklist-radio-button {
  width: 20rem !important;
  line-height: 1.25rem;
}

.cq-radio-checklist {
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  float: right !important;
  flex-direction: column;
  gap: 0.5rem;
  text-align: justify !important;
  margin-right: 1rem;
  word-break: auto-phrase;
  word-spacing: -1px;
}

.cq-page-content {
  margin-top: 1rem;
}

.cq-full-height {
  min-height: calc(100vh - 5rem);
}

.cq-login-card {
  text-align: center;
  height: 25rem;
}

.slds-accordion__section.cq-accordion {
  padding: 0px;
  margin-top: 0.5rem;
}

.cq-section-header {
  display: flex;
  background-color: #E5E5E5;
  padding: 10px;
  border-radius: 5px;
  margin-top: 0.75rem;
  margin-left: 0;
  margin-right: 0;
}

.cq-section-content{
  color: black;
  font-weight: bold;
}

.cq-accordion > .slds-accordion__content .slds-card.slds-box {
  margin-top:0.5rem;
}

.cq-layout-separator {
  display: inline-block;
  width: 1rem;
}


.cq-quick-link-container { overflow-x: auto; font-size:smaller; display: flex;}
.slds-item.cq-quick-link {display: inline-block; min-width: 150px;}
.slds-item.cq-quick-link a { color: #0071B7;}
.slds-item.cq-quick-link:before {content: none !important;}

.cq-text-badge {
    background: #f2f2f2;
    margin-left: 0.25rem;
    width: 2rem;
    height: 2rem;
    border-radius: 30px;
    text-align: center;
    color: gray;
    font-size: 0.75rem;
    vertical-align: middle;
    display: inline-block;
    line-height: 2rem;
    margin-right: -0.5rem;
}


.cq-column.cq-column_label:not:first-child {flex: 1 1 150px; flex-wrap: wrap; };
.cq-column.cq-column_label:first-child {flex: 0 0 155px;};
.cq-column.cq-column_select {flex: 1 1 300px};

.cq-column > .cq-text {
  line-height: 2rem;
}

.cq-footer-icon.slds-icon, .cq-footer-anchor {
  color: #0071B7;
  fill: #0071B7;
  margin-left: 0.5rem;
}

.slds-card__footer.cq-footer {
  padding-bottom: 0.25rem;
}

.slds-card__footer.cq-footer > div {
  margin: 0;
}

.cq-select-btn {
  font-size: 0.8rem;
}


.cq-textarea-mobile{
  overflow: hidden;
}

.slds-text-heading_small.slds-text-title_bold.cq-text {
  margin-top: 0.25rem;
}

.slds-text-heading_medium.slds-text-title_bold.cq-text {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5;
}

.slds-grid.slds-gutters.slds-grid_align-center.slds-container_medium.slds-m-top_large.cq-bottom-container {
  margin-left: auto;
  margin-right: auto;
}

.cq-image-preview {
  display: block;
  margin: 10px auto;
  border: 2px solid black;
  width: 150px;
}

.cq-sign-here-img {
  width: 150px;
  height : 200;
}

.cq-canvas-size {
  height : 200px;
  width : 575px;
}
.cq-inprogress-list{
  width: 250px !important;
}
.cq-inprogress-title{
  width: 240px !important;
}

.cq-context-object-combobox {
  margin-top: 3rem;
}
.cq-main-object-label-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
.cq-context-object-label-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
.cq-object-modal-section {
  padding: 3.5rem;
}
.cq-form-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.cq-table-mobile{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 2.5px;
}
.cq-table-column-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 5px;
}
.cq-table-mobile:nth-child(even){
  background-color: rgb(232,232,232);
}
.cq-table-mobile:nth-child(even) .cq-table-column-container{
  border-bottom: 2px solid #f2f2f2;
}
.cq-table-mobile .cq-table-column-container{
  border-bottom: 2px solid #E8E8E8;
}
.cq-table-mobile .cq-table-column-container .cq-table-textarea{
  border: none !important;
}
.cq-table-mobile:nth-child(even) .cq-table-column-container .cq-grid-input{
  background-color: #E8E8E8 !important;
  opacity: 1;
}
.cq-table-mobile .cq-table-column-container .cq-grid-input{
  background-color: #fff !important;
  opacity: 1;
  color: #000;

}
.cq-table-mobile:nth-child(even) .cq-table-column-container .cq-table-textarea{
  background-color: #E8E8E8 !important;
  border: none !important;
}.cq-print-button {
  height: 3rem;
}
.cq-input-edit .slds-input-has-icon--left .slds-input__icon, .slds-input-has-icon_left .slds-input__icon{
  color: #ffb75d !important;
}

.cq-input-edit input, .cq-input-edit.cq-select-btn-div, .cq-input-edit-combobox {
  background:linear-gradient(135deg, red 8px, white 5px);
}
.cq-input-edit .slds-checkbox .slds-checkbox_faux,.cq-input-edit.cq-table-textarea{
  background:linear-gradient(135deg, red 5px, white 5px);
}
.cq-combobox-disabled .slds-combobox__form-element {
  display: flex;
  color: #6b6865;
  background-color: #ecebea;
}
 
.cq-combobox-disabled > .slds-combobox__form-element > span {
  margin: 4px !important;
}
 
.cq-combobox-disabled .slds-combobox__form-element .slds-input__icon_right {
  visibility: hidden !important;
}
 
.cq-combobox-disabled .slds-combobox__input {
  padding: 0.25rem 1rem !important;
}
.cq-form-desktop{
  margin-top: 3rem !important;
}
.cq-form-app{
  margin-top: 3rem !important;
  display: flex;
  flex-direction: column;
  height: 90vh !important;
}
.cq-form-header{
  height: auto !important;
}
.cq-form-container{
  height: auto !important;
  overflow-y: scroll;
  padding-bottom: 1rem !important;
}
.cq-form-footer{
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
}

@media print {
  @page{
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    print-color-adjust: exact !important;
  }
  .cq-select-btn-span {
    margin-right: 2px !important;
  }

  .cq-form-table-desktop{
    page-break-inside: avoid;
  }

  html, body {
    border: 1px solid white;
    height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  .slds-global-header{
    visibility: hidden !important;
  }
  .cq-section-header{
    print-color-adjust: exact;
    background: #E5E5E5 !important;
  }
  .cq-print-button {
    visibility: hidden !important;
  }
  .cq-form-logo {
    width: 100px;
    height: 100px;
  }
  .cq-table-textarea{
    border: none !important;
    outline: none !important;
  }
  .cq-footer-anchor, .cq-footer-icon {
    display: none;
  }
  .cq-generic-file-upload > div {
    display: none;
  } 
  .cq-file-upload-text {
    display: none;
  }
  .cq-generic-file-grid {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    margin-right: 8rem !important;
    margin-left: -2rem !important;
  }
  .cq-file-attach{
    display: none !important;
  }
  .cq-file-attach-delete{
    display: none !important;
  }
  .cq-file-attach-content{
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    margin-right: 8rem !important;
    margin-left: -4rem !important;
  }
  .cq-select-btn {
    background-color: var(--button-bg-color, inherit) !important;
    color: var(--button-text-color, inherit) !important;
  }
  abbr[title]:after {
    display: none;
  }
  .slds-combobox__form-element span{
    background-color: var(--button-bg-color, inherit) !important;
    color: var(--button-text-color, inherit) !important;
  }
  .slds-combobox__input[value]{
    display: block !important;
  }
}

@media screen and (max-width: 1024px) {
  .cq-canvas-size {
    height : 200px;
    width : 460px;
  }
  .cq-image-preview {
    display: block;
    margin: px auto;
    border: 3px solid black;
    width: 200px;
  }
  .cq-sign-here-img {
    width: 95px;
    height : 200;
  } 
  .cq-inprogress-list{
    width: fit-content !important;
    font-size: 0.75rem;
  }
  .cq-inprogress-title{
    width: 130px !important;
  }
  .cq-form-list-buttons{
    margin-right: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .cq-form .MuiGrid-container {
    display: block;
  }
  .cq-select-btn {
    margin: 0.25rem 0;
  }
  .cq-column_select {
    text-align: left !important;
    margin: 0 0.75rem;
  }
  .cq-column_dependent {
    text-align: left !important;
    width: 100% !important;
  }
  .cq-column_label {
    text-align: justify !important;
    width: 100% !important;
  }
  .cq-column .cq-text {
    text-align: justify !important;
  }
  .cq-radio-checklist {
    width: 93%;
    margin-left: 0.5rem;
    float: left !important;
    margin-right: 0 !important;
  }
  .cq-checklist-radio-button {
    width: 100% !important;
  }
  .cq-count {display: inline-block};
  .cq-sign-here-img { width : 375px; height : auto;}
  .cq-image-preview {
    display: block;
    margin: px auto;
    border: 1px solid black;
    width: 375px;
  }
  .cq-canvas-size {
    height : 200px;
    width : 260px;
  }
  .cq-progress-list-btn Button{
    margin: 0;
    width: 50px !important;
  }
  .cq-inprogress-list{
    font-size: 0.75rem;
    width: fit-content !important;
  }
  .cq-inprogress-title{
    width: 120px !important;
  }
  .cq-page-content{
    padding: 0.25rem !important;
  }
  .cq-form-list-sync{
    margin-left: -15px !important;
    padding: 0 10px !important;
  }
  .cq-form-list-button {
    display: flex;
    flex-direction: row;
    margin-left: -5px !important;
  }
  .cq-form-list-button Button:last-child{
    margin-left: 5px;
  }
  .cq-column .slds-combobox_container {
    width: 95%;
  }
  .cq-print-button {
    width: 3.5rem !important;
  }
  .cq-combobox .cq-combobox-option{
    height: 150px !important;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .cq-form-content{
    height: 40vh;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 361px) and (max-width: 413px) {
  .cq-form-content{
    height: 60vh;
    overflow-y: scroll;
  }
  .cq-table-toast{
    right: 1.5rem;
    width: 80%;
  }
  .cq-table-toast button{
    margin-right: 5.5rem !important;
  }
}

@media only screen and (min-width: 414px) and (max-width: 896px) {
  .cq-table-toast{
    right: 1.5rem;
    width: 80%;
  }
  .cq-table-toast button{
    margin-right: 2.75rem !important;
  }
  .slds-card{
    padding-bottom: 1rem !important;
  }
}
.cq-radio-section{
  display: flex;
  flex-direction: column;
  width: 100%;
}