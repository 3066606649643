.cq-quick-link-container {
    margin-left: -1rem;
    background: #fafaf9;
    margin-right: -1rem;
    margin-top: 0.5rem;
    padding-left: 1.25rem;
    border: 1px solid #DDDBDA;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.cq-quick-link {
    color: gray;
    flex-wrap:wrap;
}

.cq-quick-link .cq-count {
    margin-left: .60rem;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
}

.cq-quick-link .cq-done {
    color: green;
    margin-left: 1rem;
}